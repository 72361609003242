import { Validators } from '@angular/forms';
import { Cast, Ignore, Model } from '@klickdata/core/application';
import {
    AfterModelInit,
    CastType,
    IDataModel,
    InitialValue,
    MethodType,
    ModelSync,
    Nullable,
} from '@klickdata/core/application/src/model/model-interface';
import { Customer } from '@klickdata/core/customer';
import { Prop } from '@klickdata/core/form';
import { GradeSystem } from '@klickdata/core/grade-system';
import { Media } from '@klickdata/core/media/src/media.model';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
import { Question } from '@klickdata/core/question';
import { ResourceItem, ResourceItemData } from '@klickdata/core/resource-item';
import { Task } from '@klickdata/core/task';
import { User, UserData } from '@klickdata/core/user';
import { UserNotes } from '@klickdata/core/user-notes';
import { Occasion } from '@klickdata/core/user/src/user-occasion/user-occasion.model';
import { Utils } from '@klickdata/core/util';
import { GeoLocation } from '@klickdata/shared-components/src/google-geolocation/geo-location-control/geo-location-control.component';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ResourceCategory } from './category/resource-category.model';
import { Downloads } from './download.model';
import { ResourceTag, ResourceTagData } from './tag/resource-tag.model';
import { AppScope } from './type.scope.enum';
import { ResourceType } from './type/resource-type.model';
import { ResourceStaffRoles } from './types.enum';
import { VTQData } from '@klickdata/core/vtq';
import { Evaluation } from '@klickdata/core/opportunity/src/resource-opportunity.model';
import { RoleValueType } from '@klickdata/core/user/src/user.model';

export interface ResourceData extends IDataModel {
    id?: number;
    title?: string;
    tags_attached?: boolean;
    categories_attached?: boolean;
    items_attached?: boolean;
    users_attached?: boolean;
    groups_attached?: boolean;
    folders_attached?: boolean;
    sections_attached?: boolean;
    description?: string;
    category_ids?: number[];
    tag_ids?: number[];
    customer_id?: number;
    author_id?: number;
    author_name?: string;
    tutor_name?: string;
    attested_by?: number;
    published?: string;
    occasionStatus?: string;
    last_publish?: string;
    public?: boolean;
    always_available?: boolean;
    type_id?: number;
    available_types?: string;
    occasions_started?: number;
    occasions_count?: number;
    occasions_done?: number;
    start_item?: number;
    last_item?: number;
    number_of_items?: number;
    number_of_tags?: number;
    number_of_categories?: number;
    randomize_items?: boolean;
    items_per_section?: number;
    maximum_retries?: number;
    default_alternatives_count?: number;
    time_limit?: number | string;
    access_answer_value?: string;
    navigation_value?: string;
    visible_component_value?: string;
    stop_watch?: boolean;
    menu_id?: number;
    resource_item_ids?: number[];
    item_ids?: number[];
    media_id?: number;
    media_url?: string;
    article_code?: string;
    location?: GeoLocation;
    start_date?: string;
    time_zone?: string;
    started_at?: string;
    opportunity_end_date?: string;
    opportunity_started_at?: string;
    opportunity_deleted_at?: string;
    last_action?: string;
    opportunity_start_date?: string;
    end_date?: string;
    created_at?: string;
    updated_at?: string;
    viewed_at?: string;
    grade_system_id?: number;
    ready_publish?: boolean;
    duration?: number | string;
    bullets?: string;
    goal?: string;
    copyrights?: string;
    instructions?: string;
    price?: number;
    promo_code?: string;
    play_time?: string;
    availability?: string;
    assigned_by?: UserSign[] | UserSign;
    assign_id?: number;
    recommended_by?: UserSign[];
    examination?: Examination;
    medias?: Medias;
    staff?: Staff;
    downloads?: Downloads;
    total_seats?: number;
    extra_seats?: number;
    included_in_courses?: number[];
    host?: number[];
    organizer?: string;
    educators?: Educator[];
    subtitles?: { [key: string]: string };
    ean_code?: string;
    other_info?: string;
    agenda?: string;
    audience?: string;
    pre_skills?: string;
    episode?: string;
    practice_document?: string;
    external_document?: string;
    video_document?: string;
    show_publisher_logo?: boolean;
    currency?: string;
    // Instructor refactor needed
    instructor?: Instructor;
    prompt_uuid?: any;
    users_access_open?: boolean;
    mandatory?: boolean;
    submit?: boolean;
    priority?: number;
    user?: User;
    resource_items?: ResourceItemData[];
    occasion_dates?: OccasionDate[];
    users_count?: number;
    total_users_count?: number;
    customers_count?: number;
    started_users?: number;
    completed_users?: number;
    excluded_from_section?: boolean;
    prompt?: string;

    // Available when filtered on started.
    opportunity_id?: number;

    // Language Support:
    language_id?: number;

    // statistics
    resource_started?: number;
    resource_completed?: number;
    resource_passed?: number;
    resource_failed?: number;
    total_activities?: number;

    // User Feed
    favorite?: boolean;
    like?: boolean;
    rate?: number;
    review?: string;

    /*
     * assign items
     */

    // users
    users_attach?: number[];
    users_detach?: number[];
    sync_all_users?: boolean;

    // groups
    groups_attach?: number[];
    groups_detach?: number[];
    sync_all_groups?: boolean;

    // tags
    tags_attach?: number[];
    tags_detach?: number[];

    // categories
    categories_attach?: number[];
    categories_detach?: number[];

    // customers
    customers_attach?: number[];
    customers_detach?: number[];

    // folders
    folders_attach?: number[];
    folders_detach?: number[];

    // sections
    sections_attach?: number[];
    sections_detach?: number[];
    priority_in_section?: number;

    detach_all?: ('users' | 'groups' | 'sections' | 'folders')[];
    scope_id?: AppScope;

    occasion_link?: string;
    image?: string;
    tags?: ResourceTagData[];
    author?: UserData;
    categories?: ResourceCategory[];
    items?: ResourceItemData[];

    // test or survey
    // Finish Test with submitting/answering all questions > default: False
    force_complete_sections?: boolean;
    // Take test or survey questions in order > default: false
    auto_play?: boolean;
    publish?: boolean;

    section_id?: number;
    status?: boolean;
    collaboration?: Collaboration;
    communication?: { [key: string]: any };
    address?: { [key: string]: any };
    user_collaboration?: Collaboration;
    finish_msg?: any;
    resource_notes?: UserNotes[];
    approved?: boolean;
    ai_metadata?: VTQData;
    user_permissions?: AccessControlPermission[];
    group_permissions?: AccessControlPermission[];
    short_name?: string;
    done_at?: string;
    sorting_code?: number;
    recurring?: CompetenceRecurring | any;
    occasion_status?: string;
}

export class Resource extends Model<ResourceData> implements AfterModelInit {
    @Prop()
    public id: number;

    @Prop({
        validation: Validators.required,
    })
    public title: string;
    @Prop({
        // validation: Validators.required, // Make desciption optional.
    })
    public description: string;
    @Prop({
        validation: Validators.required,
    })
    public category_ids: number[];
    @InitialValue([])
    @Prop()
    public tag_ids: number[];
    @InitialValue([])
    public customer_id: number;
    public author_id: number;
    @Prop()
    public author_name: string;
    @Prop()
    public tutor_name: string;
    public attested_by: number;
    public published: string;
    public occasionStatus: string;
    resource_notes: UserNotes[];
    @Nullable(MethodType.PUT)
    public last_publish: string;
    @Prop()
    public public: boolean;
    @Prop()
    public users_access_open: boolean;
    @Prop()
    public mandatory: boolean;

    @Prop()
    public always_available: boolean;

    @Prop({
        validation: Validators.required,
    })
    @Ignore(MethodType.PUT)
    public type_id: number;

    @Ignore()
    public publish: boolean;

    @Prop({
        validation: Validators.required,
    })
    @Prop()
    public language_id: number;

    @Prop()
    public priority: number;
    public available_types: string;
    public start_item: number;
    public last_item: number;
    public recommended_by: UserSign[];
    public randomize_items: boolean;
    public items_per_section: number;
    public force_complete_sections: boolean;
    public maximum_retries: number;
    public default_alternatives_count: number;
    @Prop()
    public time_limit: number | string;
    public access_answer_value: string;
    public navigation_value: string;
    public visible_component_value: string;
    public stop_watch: boolean;
    public menu_id: number;
    @Prop()
    @Nullable(MethodType.PUT)
    @Cast(CastType.PRIMITIVE, 'number', true)
    public media_id: number;
    @Prop()
    public article_code: string;
    @Prop()
    public time_zone: string;
    public grade_system_id: number;
    public user: User;

    @Prop()
    @Cast(CastType.MOMENT, 'YYYY-MM-DD HH:mm')
    @Nullable(MethodType.PUT)
    public start_date: moment.Moment;
    @Cast(CastType.MOMENT, 'YYYY-MM-DD')
    public started_at: moment.Moment;
    @Cast(CastType.MOMENT, 'YYYY-MM-DD')
    public opportunity_end_date: moment.Moment;
    @Cast(CastType.MOMENT, 'YYYY-MM-DD')
    public opportunity_started_at: moment.Moment;
    @Cast(CastType.MOMENT, 'YYYY-MM-DD')
    public opportunity_deleted_at: moment.Moment;
    @Cast(CastType.MOMENT, 'YYYY-MM-DD HH:mm')
    public last_action: moment.Moment;
    @Cast(CastType.MOMENT, 'YYYY-MM-DD')
    public opportunity_start_date: moment.Moment;

    @Prop()
    @Cast(CastType.MOMENT, 'YYYY-MM-DD HH:mm')
    @Nullable(MethodType.PUT)
    public end_date: moment.Moment;
    @Cast(CastType.MOMENT)
    public viewed_at: moment.Moment;

    @InitialValue([])
    public resource_item_ids: number[];
    @InitialValue([])
    public item_ids: number[];

    // Available when filtered on started.
    public opportunity_id: number;

    // User Feed
    @Ignore()
    @InitialValue({})
    public feeds: Feeds;

    /**
     * Assign/de-assign resource with items
     */

    // users
    @InitialValue([])
    users_attach: number[];
    @InitialValue([])
    users_detach: number[];
    sync_all_users: boolean;

    // groups
    @InitialValue([])
    groups_attach: number[];
    @InitialValue([])
    groups_detach: number[];
    sync_all_groups: boolean;

    /**
     * Ignore from adding to payload.
     */
    @Ignore()
    checked: boolean;
    @Ignore()
    occasions_started: number;
    @Ignore()
    occasions_count: number;
    @Ignore()
    occasions_done: number;
    @Ignore()
    @Cast(CastType.MOMENT)
    @Ignore()
    public created_at: moment.Moment;

    @Cast(CastType.MOMENT)
    @Ignore()
    public updated_at: moment.Moment;

    @Ignore()
    public tags_attached: boolean;
    @Ignore()
    public items_attached: boolean;
    @Ignore()
    public categories_attached: boolean;
    @Ignore()
    public categories: ResourceCategory[];
    @Ignore()
    public users_attached: boolean;
    @Ignore()
    public groups_attached: boolean;
    @Ignore()
    public folders_attached: boolean;
    @Ignore()
    public sections_attached: boolean;
    @Ignore()
    public auto_play: boolean;
    @Ignore()
    public purchased: boolean;
    @Ignore()
    public number_of_items: number;
    @Ignore()
    public number_of_tags: number;
    @Ignore()
    public number_of_categories: number;
    @Ignore()
    public ready_publish: boolean;
    @Ignore()
    public users_count: number;
    @Ignore()
    public total_users_count: number;
    @Ignore()
    public customers_count: number;
    @Ignore()
    public started_users: number;
    @Ignore()
    public completed_users: number;
    @Ignore()
    public excluded_from_section: boolean;
    @Ignore()
    @InitialValue(0)
    public priority_in_section: number;
    // handle selection
    @Ignore()
    public selected: boolean;
    // Handle loading.
    @Ignore()
    public loading: boolean;
    @Ignore()
    resource_started = 0;
    @Ignore()
    resource_completed = 0;
    @Ignore()
    resource_passed = 0;
    @Ignore()
    resource_failed = 0;
    @Ignore()
    total_activities = 0;
    @Ignore()
    public resourceType: ResourceType;
    @Prop()
    public resource_items?: ResourceItemData[];
    @Ignore()
    public submit: boolean;
    @Ignore()
    public type_value: string;
    @Prop()
    public duration: number | string;
    @Prop({
        validation: Validators.required,
    })
    public bullets?: string;
    @Prop()
    public goal?: string;
    @Prop()
    public copyrights?: string;
    @Prop()
    public instructions?: string;
    @Prop()
    @Nullable()
    public price?: number;
    @Prop()
    public promo_code?: string;
    @Prop()
    public play_time?: string;
    public availability?: string;
    @Prop()
    public assigned_by?: UserSign[] | UserSign;
    @Prop()
    public assign_id?: number;
    @Cast(CastType.OBJECT)
    @Prop()
    public examination?: Examination;
    @Cast(CastType.CLOSURE, Utils.nullableSync)
    @Prop()
    public medias?: Medias;
    @Cast(CastType.OBJECT)
    @Prop()
    public staff?: Staff;
    @Cast(CastType.OBJECT)
    @Prop()
    public location?: GeoLocation;
    @Prop()
    public total_seats?: number;
    @Prop()
    public host?: number[];
    @Prop()
    public organizer?: string;
    @Prop()
    public extra_seats?: number;
    @Prop()
    public included_in_courses?: number[];
    @Prop()
    @Cast(CastType.OBJECT)
    subtitles: { [key: string]: string };
    @Prop()
    public ean_code?: string;
    @Prop()
    public other_info?: string;
    @Prop()
    agenda?: string;
    @Prop()
    audience?: string;
    @Prop()
    pre_skills?: string;
    @Prop()
    episode?: string;
    @Prop()
    practice_document?: string;
    @Prop()
    external_document?: string;
    @Prop()
    video_document?: string;
    @Prop()
    public verified?: boolean;
    @Prop()
    public show_publisher_logo?: boolean;
    @Prop()
    @Nullable()
    @Cast(CastType.CLOSURE, (items: OccasionDate[]) => items.map((item) => (delete item.selected, item)))
    public occasion_dates?: OccasionDate[];
    @Prop()
    @InitialValue('USD')
    public currency?: string;

    // @Nullable(MethodType.PUT)
    // @Cast(CastType.OBJECT)
    // @Prop()
    public instructor: Instructor;
    @Ignore()
    public prompt_uuid?: any;
    @Ignore()
    public occasions: Occasion[];
    @Ignore()
    public assignStatus: string;
    @Ignore()
    @Cast(CastType.MOMENT)
    public assign_deleted_at: string;
    @Ignore()
    public downloads?: Downloads;
    @Ignore()
    public exercise_downloads?: Downloads;
    public scope_id: AppScope;
    @Ignore()
    public items: ResourceItem[];
    @Ignore()
    public quote_ids: number[];
    @Ignore()
    public related_resources: ResourceData[];
    @Ignore()
    public done: string;
    @Cast(CastType.CLOSURE, Utils.modelSync)
    users?: ModelSync;
    @Cast(CastType.CLOSURE, Utils.modelSync)
    groups?: ModelSync;
    @Cast(CastType.CLOSURE, Utils.modelSync)
    teams?: ModelSync;
    public educators: Educator[];
    public hosts: Educator[];
    public organizers: Educator[];
    public publishers: Educator[];
    public managers: Educator[];
    /**
     * Observables will be ignore when generate payload on base module.
     * And you still can use @see @Ignore()
     */
    public questions: Observable<Question[]>;
    public type: Observable<ResourceType>;
    public items$: Observable<ResourceItem[]>;
    public item$: Observable<ResourceItem>;
    public categories$: Observable<ResourceCategory[]>;
    public tags$: Observable<ResourceTag[]>;
    public gradeSystem: Observable<GradeSystem>;
    public author$: Observable<User>;
    public managers$: Observable<Educator[]>;
    public educators$: Observable<Educator[]>;
    public publishers$: Observable<Educator[]>;
    public hosts$: Observable<Educator[]>;
    public organizers$: Observable<Educator[]>;
    public customer: Observable<Customer>;
    public opportunity: Observable<ResourceOpportunity>;
    public media$: Observable<Media>;
    @Ignore()
    public media_url: string;
    @Ignore()
    public video_url: string;
    public media_fallback_url: string;
    @Ignore()
    public opportunity_assign_date: string;
    @Ignore()
    public label: string;
    @Cast(CastType.OBJECT)
    @Prop()
    public collaboration: Collaboration;
    @Cast(CastType.OBJECT)
    @Prop()
    public communication: { [key: string]: any };
    @Cast(CastType.OBJECT)
    @Prop()
    public address: { [key: string]: any };
    @Prop()
    public finish_msg: any;

    // Evaluation needed keys
    approved?: boolean;
    occasion_id?: number;
    evaluation?: Evaluation;
    @Ignore()
    enrollment: ResourceApproval;
    @Ignore()
    publication: ResourceApproval;
    @Ignore()
    publishment: ResourceApproval;
    @Ignore()
    enrollments: ResourceApproval[];
    @Ignore()
    user_collaboration: Collaboration;
    @Ignore()
    enroll_approval_status: null | 'required' | 'pending' | 'approved' | 'rejected';
    @Ignore()
    available_seats: number;
    user_permissions?: AccessControlPermission[];
    group_permissions?: AccessControlPermission[];
    @Ignore()
    short_name: string;
    @Cast(CastType.MOMENT)
    @Ignore()
    public done_at: moment.Moment;
    @Ignore()
    public occasion_status: string;
    @Ignore()
    public occasion_expiry: string;
    @Prop()
    public sorting_code: number;
    public isPublished(): boolean {
        return !!(this.published && this.last_publish);
    }

    @Ignore()
    recurring?: CompetenceRecurring;

    public isAvailable(): boolean {
        if (this.always_available) {
            return true;
        }

        if (!this.start_date && !this.end_date) {
            return true;
        }

        if (this.start_date && this.end_date) {
            return this.start_date.isBefore() && this.end_date.isAfter();
        }

        if (this.start_date && !this.end_date) {
            return this.start_date.isBefore();
        }

        if (!this.start_date && this.end_date) {
            return this.end_date.isAfter();
        }

        return false;
    }
    get finalOccasion(): Occasion {
        return Array.isArray(this.occasions) ? this.occasions[0] : this.occasions;
    }

    get assignedByName() {
        return Array.isArray(this.assigned_by) ? this.assigned_by[0]?.name : this.assigned_by?.name;
    }

    get favorite(): boolean {
        return this.feeds?.favorite;
    }

    set favorite(favorite: boolean) {
        this.feeds.favorite = favorite;
    }

    get like(): boolean {
        return this.feeds?.like;
    }
    set like(like: boolean) {
        this.feeds.like = like;
    }

    get rate(): number {
        return this.feeds?.rate;
    }
    set rate(rate: number) {
        this.feeds.rate = rate;
    }

    get review(): string {
        return this.feeds?.review;
    }
    set review(review: string) {
        this.feeds.review = review;
    }

    afterModelInit(data: ResourceData): void {
        if (!data.currency) {
            data.currency = 'USD';
        }
    }
}

export interface ResourceWithQuery {
    resource: Resource;
    params: {};
}

export interface CoursePlanResource {
    resource: Resource;
    count: number;
    doneCount: number;
    opportunities: ResourceOpportunity[];
}
export interface ApprovalMessage {
    subject?: string;
    body?: string;
    author_id?: number;
    parent_id?: number;
}
export interface ResourceApproval {
    requested_at?: string;
    reviewed_at?: string;
    approval_status?: 'pending' | 'approved' | 'rejected';
    applicant?: UserData;
    admin?: UserData;
    collaboration_id?: number;
}
export interface Collaboration {
    view: boolean;
    mandatory: boolean;
    enroll_approval_required: boolean;
    signoff_required: boolean;
    edit?: boolean;
    publish?: boolean;
    tutor?: boolean;
}
export interface StatusCollaboration {
    view: StatusCollaborationItem;
    mandatory: StatusCollaborationItem;
    enroll_approval_required: StatusCollaborationItem;
    signoff_required: StatusCollaborationItem;
}
export interface StatusCollaborationItem {
    status: boolean;
    exclude: boolean;
}
export interface AccessControlPermission {
    id?: number;
    permission_type?: string;
    permission_value?: { value: true | false; disabled?: true | false };
}

export interface Educator {
    id?: number;
    name: string;
    about?: string;
    fname?: string;
    lname?: string;
    title?: string;
    phone?: string;
    email?: string;
    role_value?: RoleValueType;
    media_id?: number;
    media?: Media;
    media$?: Observable<Media>;
    customer_id?: number;
    customer?: Customer;
    resource_id?: number;
    role?: ResourceStaffRoles;
    task?: Task;
    communications?: {};
}
export interface Instructor {
    name: string;
    images: number[];
}

export interface UserSign {
    name: string;
    id: number;
}

export interface Examination {
    type: 'Participation' | 'Diploma' | 'Certification';
    label?: string;
    grade_system_id: number;
    final_test_id: number;
    diploma_level: number;
}
export interface Medias {
    cover?: number[];
    background?: number[];
    screenshot?: number[];
    publisher?: number[];
    trailer?: number[];
    brochure?: number[];
    exercise?: number[];
    logotype?: number[];
    file?: number[];
    footerLogotype?: number[];
}
export interface Staff {
    educator?: number[];
    manager?: number[];
    publisher?: number[];
    host?: number[];
    organizer?: number[];
    creator?: number[];
    quotee?: number[];
    assignee?: number[];
    contact?: number[];
}

export interface Feeds {
    favorite: boolean;
    like: boolean;
    rate: number;
    review: string;
}
export interface OccasionDate {
    start_date: string;
    end_date: string;
    id?: number;
    isValid?: boolean;
    selected?: boolean;
}
export interface ResourceEventType {
    id: number;
    type: string;
}
export interface Currency {
    code: string;
    sign: string;
    label: string;
}

export interface CompetenceRecurring {
    recurring?: string;
    start_date?: string;
    sorting_code?: string;
}
