import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { InfoDialogComponent } from '@klickdata/core/application/src/info-dialog/info-dialog.component';
import { IDataModel, IModel, ModelSync } from '@klickdata/core/application/src/model/model-interface';
import { MediaType } from '@klickdata/core/media/src/media-type';
import { Media } from '@klickdata/core/media/src/media.model';
import { PaymentItem, PaymentMethod } from '@klickdata/core/payment/src/payment.model';
import { Question } from '@klickdata/core/question';
import { ParentMapper } from '@klickdata/core/resource';
import { CompetenceRecurring } from '@klickdata/core/resource/src/resource.model';
import { Filter, FilterCollection, SelectFilterOption } from '@klickdata/core/table';
import { NotePrivacy } from '@klickdata/core/user-notes';
import { ObjectValidator } from 'apps/klickdata/src/app/shared/validator/object.validator';
import * as moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';
interface RgbaObject {
    r: number;
    g: number;
    b: number;
}

export class Utils {
    /**
     * Handle youtube links validators
     * @param url expected youtube link.
     */
    private static getYoutubeURLMatcher(url: string) {
        return url && url.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/);
    }

    private static getVimeoURLMatcher(url: string) {
        return (
            url &&
            url.match(
                /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
            )
        );
    }

    static isYoutubeURL(url: string): boolean {
        return this.isYoutubeId(this.getYoutubeURLMatcher(url));
    }

    private static isYoutubeId(matcher: RegExpMatchArray): boolean {
        return matcher && matcher[2] && matcher[2].length === 11;
    }

    static isVimeoURL(url: string): boolean {
        return this.isVimeoId(this.getVimeoURLMatcher(url));
    }

    private static isVimeoId(matcher: RegExpMatchArray): boolean {
        return matcher && !!matcher[4];
    }

    static getYoutubeMedia(url: string): Media {
        const matcher = this.getYoutubeURLMatcher(url);
        if (this.isYoutubeId(matcher)) {
            return <Media>{ src: matcher[2], provider: 'youtube', mediaType: MediaType.VIDEO };
        }
    }

    private static getVimeoMedia(url: string): Media {
        const matcher = this.getVimeoURLMatcher(url);
        if (this.isVimeoId(matcher)) {
            return <Media>{ src: matcher[4], provider: 'vimeo', mediaType: MediaType.VIDEO };
        }
    }

    private static getWebsiteMedia(url: string): Media {
        if (this.internalLink(url)) {
            return <Media>{ url: url, type: 'website', mediaType: MediaType.WEBSITE };
        }
    }

    static getAssetsImg(path: string): Media {
        if (path.indexOf('assets/images/') !== -1) {
            return <Media>{ src: path, mediaType: MediaType.IMAGE };
        }
    }
    static getSocialMedias(): {
        src: string;
        key: string;
        name: string;
        isSocialMedia?: boolean;
        validationPattern?: string;
        inputPlaceHolder?: string;
    }[] {
        return [
            {
                key: 'facebook',
                src: 'assets/images/facebook.svg',
                name: 'Facebook',
                isSocialMedia: true,
                validationPattern: '/facebook.com/([a-zA-Z0-9._]+)/i',
                inputPlaceHolder: $localize`Enter messanger link`,
            },
            {
                key: 'twitter',
                src: 'assets/images/twitter.svg',
                name: 'X',
                isSocialMedia: true,
                validationPattern: '/twitter.com/([a-zA-Z0-9_]+)/i',
                inputPlaceHolder: $localize`Enter X link`,
            },
            {
                key: 'linkedin',
                src: 'assets/images/linkedin.svg',
                name: 'Linkedin',
                isSocialMedia: true,
                validationPattern: '/linkedin.com/in/([a-zA-Z0-9-_]+)/i',
                inputPlaceHolder: $localize`Enter linkedin link`,
            },
            {
                key: 'instagram',
                src: 'assets/images/instagram.svg',
                name: 'Instagram',
                isSocialMedia: true,
                validationPattern: '/instagram.com/([a-zA-Z0-9._]+)/i',
                inputPlaceHolder: $localize`Enter instagram link`,
            },
            {
                key: 'discord',
                src: 'assets/images/discord.svg',
                name: 'Discord',
                isSocialMedia: true,
                validationPattern: '/discord.com/([a-zA-Z0-9_]+)/i',
                inputPlaceHolder: $localize`Enter discord link`,
            },
            {
                key: 'mail',
                src: 'assets/images/mail.svg',
                name: 'Mail',
                validationPattern: '',
                inputPlaceHolder: $localize`Enter e-mail`,
            },
            {
                key: 'phone',
                src: 'assets/images/phone.svg',
                name: 'Phone',
                validationPattern: '/^+[0-9]{1,3}-?[0-9]{6,14}$/',
                inputPlaceHolder: $localize`Enter phone number`,
            },
            {
                key: 'skype',
                src: 'assets/images/skype.svg',
                name: 'Skype',
                isSocialMedia: true,
                validationPattern: '/skype.com/([a-zA-Z0-9._]+)/i',
                inputPlaceHolder: $localize`Enter skype account link`,
            },
            {
                key: 'sms',
                src: 'assets/images/sms.svg',
                name: 'SMS',
                validationPattern: '/^+[0-9]{1,3}-?[0-9]{6,14}$/',
                inputPlaceHolder: $localize`Enter phone number`,
            },
            {
                key: 'telegram',
                src: 'assets/images/telegram.svg',
                name: 'Telegram',
                isSocialMedia: true,
                validationPattern: '/telegram.me/([a-zA-Z0-9_]+)/i',
                inputPlaceHolder: $localize`Enter phone number`,
            },
            {
                key: 'whatsapp',
                src: 'assets/images/whatsapp.svg',
                name: 'Whatsapp',
                isSocialMedia: true,
                validationPattern: '/^+[0-9]{1,3}-?[0-9]{6,14}$/',
                inputPlaceHolder: '',
            },
            {
                key: 'meet',
                src: 'assets/images/meet.svg',
                name: 'Google meet',
                isSocialMedia: true,
                validationPattern: '/meet.google.com/([a-zA-Z0-9_]+)/i',
                inputPlaceHolder: $localize`Enter meet link`,
            },
            {
                key: 'signal',
                src: 'assets/images/signal.svg',
                name: 'Signal',
                isSocialMedia: true,
                validationPattern: '/signal.org/([a-zA-Z0-9_]+)/i',
                inputPlaceHolder: $localize`Enter signal link`,
            },
            {
                key: 'slack',
                src: 'assets/images/slack.svg',
                name: 'Slack',
                isSocialMedia: true,
                validationPattern: null,
                inputPlaceHolder: $localize`Enter slack link`,
            },
            {
                key: 'teams',
                src: 'assets/images/teams.svg',
                name: 'Teams',
                isSocialMedia: true,
                validationPattern: '/teams.microsoft.com/([a-zA-Z0-9_]+)/i',
                inputPlaceHolder: $localize`Enter teams link`,
            },
        ];
    }
    static onCommunicationClick(key: string, value: string) {
        switch (key) {
            case 'mail':
                window.open('mailto:' + value, '_blank');
                break;
            case 'phone':
                window.open('tel:' + value, '_blank');
                break;
            case 'sms':
                window.open('sms:' + value, '_blank');
                break;
            default:
                window.open(value, '_blank');
        }
    }
    static getValuedKeysFromArrayOfObjects(items: any[]): string[] {
        const valuedDatakeys = [];
        items
            .map((item) => {
                return Object.keys(item).reduce((acc, el) => {
                    if (item[el] !== null && item[el] !== '') {
                        acc[el] = item[el];
                    }
                    return acc;
                }, {});
            })
            .forEach((item) => {
                Object.keys(item).forEach((itemKey) => {
                    if (valuedDatakeys.indexOf(itemKey) === -1) {
                        valuedDatakeys.push(itemKey);
                    }
                });
            });
        return valuedDatakeys;
    }

    static getMatchedItemsFromArrays(first: any[], second: any[]): any[] {
        return first.filter((element) => second.includes(element));
    }

    static removeItemByIdFromArray(id: any, array: any[]): any[] {
        const index = array.findIndex((item) => item.id === id);
        if (index !== -1) {
            array.splice(index, 1);
        }
        return array;
    }

    static functionNotImpl(dialog: MatDialog) {
        dialog.open(InfoDialogComponent, {
            disableClose: false,
            data: {
                contentBody: $localize`:@@thisFunctionWillSoonWorkGreat! :This function will soon work great! `,
                neutralBtn: $localize`:@@ok:Ok`,
            },
        });
    }

    private static getLinkMedia(url: string): Media {
        if (url.match(/(http|https)?:.*/)) {
            return <Media>{ url: url, type: 'url_material', mediaType: MediaType.URL };
        }
    }
    static hexColorToRgba(hex: string, alpha: number = 1): RgbaObject {
        // Remove the hash (#) if it exists
        hex = hex.replace(/^#/, '');

        // Parse the hex values
        const bigint = parseInt(hex, 16);

        // Extract RGB components
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        // Calculate the alpha in the range [0, 1]
        const parsedAlpha = Math.min(1, Math.max(0, alpha));

        // Construct the RGBA object
        return { r, g, b };
    }
    static getAllCurrencies() {
        return [
            { code: 'USD', sign: '$', label: 'US Dollar' },
            { code: 'EUR', sign: '€', label: 'Euro' },
            { code: 'SEK', sign: 'SEK', label: 'Swedish Krona' },
            { code: 'EGP', sign: 'E£', label: 'Egyptian Pound' },
            { code: 'SAR', sign: '﷼', label: 'Saudi Riyal' },
            { code: 'GBP', sign: '£', label: 'British Pound Sterling' },
            { code: 'INR', sign: '₹', label: 'Indian Rupee' },
            { code: 'JPY', sign: '¥', label: 'Japanese Yen' },
            { code: 'KRW', sign: '₩', label: 'South Korean Won' },
            { code: 'NGN', sign: '₦', label: 'Nigerian Naira' },
            { code: 'PHP', sign: '₱', label: 'Philippine Peso' },
            { code: 'PLN', sign: 'zł', label: 'Polish Zloty' },
            { code: 'PYG', sign: '₲', label: 'Paraguayan Guarani' },
            { code: 'THB', sign: '฿', label: 'Thai Baht' },
            { code: 'UAH', sign: '₴', label: 'Ukrainian Hryvnia' },
            { code: 'VND', sign: '₫', label: 'Vietnamese Dong' },
        ];
    }

    static getUserStatusOptions(): SelectFilterOption[] {
        return [
            { title: $localize`:@@active:Active`, value: 'active', icon: 'notifications_active' },
            { title: $localize`Inactive (30d)`, value: 'inactive', icon: 'verified_user' },
            { title: $localize`Unactivated`, value: 'unactivated', icon: 'sync_disabled' },
            { title: $localize`Deleted`, value: 'deleted', icon: 'auto_delete' },
            { title: $localize`Expired`, value: 'expired', icon: 'event_busy' },
            { title: $localize`Imported`, value: 'imported', icon: 'label_important' },
        ];
    }
    static getCustomerContactTypeOptions(): SelectFilterOption[] {
        return [
            { title: 'OA / Outgoing attempt', value: 'OA', icon: '' },
            { title: 'OH / Outbound hit', value: 'OH', icon: '' },
            { title: 'OO / Outgoing order', value: 'OO', icon: '' },
            { title: 'OX / Outgoing other (sms)', value: 'OX', icon: '' },
            { title: 'OE/ Outgoing email', value: 'OE', icon: '' },
            { title: 'OL/ Outgoing LinkedIN', value: 'OL', icon: '', class: 'has-divider-next' },
            { title: 'OM/ Meeting with customer or quote', value: 'OM', icon: '' },
            { title: 'OQ/ Outgoing quotation', value: 'OQ', icon: '' },
            { title: 'OCM/ Outgoing customer meeting', value: 'OCM', icon: '' },
            { title: 'OD/ Outgoing demo of K3/ KLMS', value: 'OD', icon: '' },
            { title: 'OZ/ Outgoing Zoom or Team Meeting', value: 'OZ', icon: '', class: 'has-divider-next' },
            { title: 'IOM/Incoming order mail', value: 'IOM', icon: '' },
            { title: 'IOP/Incoming orders phone/fax/visit', value: 'IOP', icon: '' },
            { title: 'IL/ Incoming Lead interest', value: 'IL', icon: '' },
            { title: 'IO / Incoming order', value: 'IO', icon: '' },
            { title: 'IA / Incoming other', value: 'IA', icon: '' },
            { title: 'IS / Inbound support', value: 'IS', icon: '' },
            { title: 'IE/ Incoming email', value: '', icon: 'IE', class: 'has-divider-next' },
            { title: 'IDQ/ Incoming delivery question', value: 'IDQ', icon: '' },
            { title: 'IM / Incoming meeting and visit', value: 'IM', icon: '' },
            { title: 'RZ/ Recorded Zoom & Video', value: 'RZ', icon: '' },
            { title: 'BP/ Bolinder & Partners case', value: 'BP', icon: '' },
            { title: 'GK/Grow Knowledge Case', value: 'GK', icon: '' },
            { title: 'KD/ Klick Data Case', value: 'KD', icon: '' },
        ];
    }
    static getPaymentMethods(): PaymentMethod[] {
        return [
            {
                id: 0,
                name: 'paypal',
                title: $localize`Pay with Paypal or Debit/Credit card`,
                logo_url: 'assets/images/paypal_logo.png',
                disabled: false,
            },
            {
                id: 1,
                name: 'paytabs',
                title: $localize`Pay with Paytabs or Debit/Credit card`,
                logo_url: 'assets/images/paytabs_logo.png',
                disabled: false,
            },
            {
                id: 2,
                name: 'swish',
                title: $localize`Pay via Swish (Sweden only)`,
                logo_url: 'assets/images/swish_logo.png',
                disabled: true,
            },
            {
                id: 3,
                name: 'apple',
                title: $localize`Pay by Apple pay`,
                logo_url: 'assets/images/apple_pay_logo.png',
                disabled: true,
            },
        ];
    }
    static getChatIoPaymentOptions(): PaymentItem[] {
        return [
            {
                id: 0,
                price: 79,
                currency: 'USD',
                name: 'Individual / 2024',
                recurrent: 'One time',
                subTitle: '1 User Individual',
                subsPlan: {
                    plan: 'year',
                    user_count: '1',
                },
            },
            {
                id: 1,
                price: 29,
                currency: 'USD',
                name: 'Individual / Month',
                recurrent: 'Mountly',
                subTitle: '1 User Individual',
                subsPlan: {
                    plan: 'monthly',
                    user_count: '1',
                },
            },
            {
                id: 2,
                price: 199,
                currency: 'USD',
                name: 'Individual / Year',
                recurrent: 'Yearly / save 60%',
                subTitle: '1 User Individual',
                subsPlan: {
                    plan: 'yearly',
                    user_count: '1',
                },
            },
            {
                id: 3,
                price: 1999,
                currency: 'USD',
                name: 'Team 10 Users',
                recurrent: 'Yearly',
                subTitle: '10 Users',
                subsPlan: {
                    plan: 'yearly',
                    user_count: '10',
                },
            },
            {
                id: 4,
                price: 7999,
                currency: 'USD',
                name: 'Team 50 Users',
                recurrent: 'Yearly',
                subTitle: '50 Users',
                subsPlan: {
                    plan: 'yearly',
                    user_count: '50',
                },
            },
        ];
    }
    static getCustomerSizeOptions(): SelectFilterOption[] {
        return [
            { title: '1 - 5', value: '1-5', icon: 'badge' },
            { title: '6 - 10', value: '6-10', icon: 'badge' },
            { title: '11 - 20', value: '11-20', icon: 'badge' },
            { title: '21 - 50', value: '21-50', icon: 'badge' },
            { title: '51 - 100', value: '51-100', icon: 'badge' },
            { title: '101 - 250', value: '101-250', icon: 'badge' },
            { title: '251 - 500', value: '251-500', icon: 'badge' },
            { title: '501 - 1000', value: '501-1000', icon: 'badge' },
            { title: '+1000', value: '+1000', icon: 'badge' },
        ];
    }
    static getGeneralModelStatusSelectOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Public`, value: 'public', icon: 'public' },
            { title: $localize`None Public`, value: 'not_public', icon: 'public_off' },
            {
                title: $localize`Published`,
                value: 'published',
                icon: 'published_with_changes',
                class: 'material-icons-outlined',
            },
            { title: $localize`Not Published yet`, value: 'not_published', icon: 'unpublished' },
            { title: $localize`Deleted`, value: 'deleted', icon: 'auto_delete' },
        ];
    }

    static getResourceTypeOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Course`, value: 3, icon: 'kd-icon-task', isMainResource: true },
            { title: $localize`Test`, value: 5, icon: 'kd-icon-test', isMainResource: true },
            { title: $localize`Material`, value: 7, icon: 'kd-icon-document', isMainResource: true },
            { title: $localize`Survey`, value: 4, icon: 'kd-icon-survey', isMainResource: true },
            { title: $localize`Ecourse`, value: 6, icon: 'kd-icon-player', isMainResource: true },
        ];
    }

    /**
     * Action event source from ActivityEventEnum
     * https://git.klickportalen.se/klickdata2016/nk3-datastore/blob/develop/app/Klickdata/ActionLog/ActivityEventEnum.php
     * @returns
     */
    static getActionLogEventOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Assigned`, value: 'assigned', icon: 'assignment_ind', data: { isResRelated: true } },
            {
                title: $localize`Unassigned`,
                value: 'unassigned',
                icon: 'assignment_returned',
                data: { isResRelated: true },
            },
            {
                title: $localize`Cancelled`,
                value: 'cancelled',
                icon: 'cancel',
                data: { isResRelated: true },
                color: '#e44a66',
            },
            {
                title: $localize`Completed`,
                value: 'completed',
                icon: 'task_alt',
                data: { isResRelated: true },
                color: '#bfd8d0',
            },
            {
                title: $localize`Created`,
                value: 'created',
                icon: 'drive_file_rename_outline',
                data: { isResRelated: true },
            },
            { title: $localize`Deleted`, value: 'deleted', icon: 'delete', data: { isResRelated: true } },
            { title: $localize`Login`, value: 'login', icon: 'login', data: { isResRelated: false } },
            { title: $localize`Activate`, value: 'activate', icon: 'contact_mail', data: { isResRelated: false } },
            { title: $localize`Activated`, value: 'activated', icon: 'mark_email_read', data: { isResRelated: false } },
            { title: $localize`Deactivated`, value: 'deactivated', icon: 'person_off', data: { isResRelated: false } },
            {
                title: $localize`Reminder`,
                value: 'reminder',
                icon: 'notifications_paused',
                data: { isResRelated: false },
            },
            { title: $localize`Invite`, value: 'invite', icon: 'contact_phone', data: { isResRelated: false } },
            { title: $localize`Logout`, value: 'logOut', icon: 'logout', data: { isResRelated: false } },
            { title: $localize`Ongoing`, value: 'ongoing', icon: 'cached', data: { isResRelated: true } },
            { title: $localize`Overdue`, value: 'overdue', icon: 'event_busy', data: { isResRelated: true } },
            { title: $localize`Restored`, value: 'restored', icon: 'restore_page', data: { isResRelated: true } },
            {
                title: $localize`Started`,
                value: 'started',
                icon: 'start',
                data: { isResRelated: true },
                color: '#ff9961',
            },
            { title: $localize`Updated`, value: 'updated', icon: 'restart_alt', data: { isResRelated: true } },
            {
                title: $localize`Published`,
                value: 'published',
                icon: 'published_with_changes',
                data: { isResRelated: true },
            },
            { title: $localize`Unpublished`, value: 'unpublished', icon: 'unpublished', data: { isResRelated: true } },
            { title: $localize`Public`, value: 'public', icon: 'public', data: { isResRelated: true } },
            { title: $localize`Unpublic`, value: 'unpublic', icon: 'public_off', data: { isResRelated: true } },
            { title: $localize`Duration`, value: 'durationtime', icon: 'timer', data: { isResRelated: true } },
            { title: $localize`Prompt`, value: 'user_prompt', icon: 'smart_toy', data: { isResRelated: false } },
        ];
    }
    static getKDColors(): string[] {
        return ['#e44a66', '#93cbd1', '#3e5365', '#ff9961', '#bfd8d0'];
    }
    static getNotesPrivacyOptions(): NotePrivacy[] {
        return [
            { value: 'private', label: $localize`Private` },
            { value: 'user', label: $localize`Learners` },
            { value: 'group', label: $localize`Groups` },
            { value: 'academy', label: $localize`Academy` },
            { value: 'public', label: $localize`Public` },
        ];
    }
    static getNotesVisibilityOptions(): SelectFilterOption[] {
        return [
            {
                title: $localize`Master`,
                value: 'master',
                icon: 'admin_panel_settings',
            },
            {
                title: $localize`Private`,
                value: 'private',
                icon: 'lock',
            },
            {
                title: $localize`Public`,
                value: 'public',
                icon: 'public',
            },
        ];
    }
    static getPredefinedTimeSpentOptions(): SelectFilterOption[] {
        return [
            {
                title: $localize`24H`,
                value: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                icon: 'av_timer',
                class: '24h',
                data: {
                    tooltipValue: $localize`Last 24 hours`,
                },
            },
            {
                title: $localize`7D`,
                value: moment().subtract(1, 'week').format('YYYY-MM-DD'),
                icon: 'av_timer',
                class: '7d',
                data: {
                    tooltipValue: $localize`Last 7 Days`,
                },
            },
            {
                title: $localize`30D`,
                value: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                icon: 'av_timer',
                class: '30d',
                data: {
                    tooltipValue: $localize`Last 30 Days`,
                },
            },
            {
                title: $localize`180D`,
                value: moment().subtract(6, 'months').format('YYYY-MM-DD'),
                icon: 'av_timer',
                class: '180d',
                data: {
                    tooltipValue: $localize`Last 6 months`,
                },
            },
            {
                title: $localize`Year`,
                value: moment().subtract(1, 'year').format('YYYY-MM-DD'),
                icon: 'av_timer',
                class: 'year',
                data: {
                    tooltipValue: $localize`Last Year`,
                },
            },
            {
                title: $localize`Defined period`,
                value: 'select_date_range',
                icon: 'date_range',
                class: 'date_range',
                data: {
                    tooltipValue: $localize`Choose a period with start and end date`,
                },
            },
        ];
    }

    static getSectionsSortingOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Recommended`, value: 'priority', icon: 'thumb_up_off_alt' },
            {
                title: $localize`Popular`,
                value: 'popularity',
                icon: 'grade',
                class: 'material-icons-outlined',
            },
            { title: $localize`Latest`, value: 'updated_at', icon: 'update' },
            { title: $localize`Alphabetical`, value: 'title', icon: 'sort_by_alpha' },
        ];
    }
    static getWorldTimeZones(): SelectFilterOption[] {
        return [
            { title: 'UTC-12:00 (BST) Baker Island Time', value: 'BST', icon: '' },
            { title: 'UTC-11:00 (SST) Samoa Standard Time', value: 'SST', icon: '' },
            { title: 'UTC-10:00 (HST) Hawaii-Aleutian Standard Time', value: 'HST', icon: '' },
            { title: 'UTC-9:00 (AKST) Alaska Standard Time', value: 'AKST', icon: '' },
            { title: 'UTC-8:00 (PST) Pacific Standard Time', value: 'PST', icon: '' },
            { title: 'UTC-7:00 (MST) Mountain Standard Time', value: 'MST', icon: '' },
            { title: 'UTC-6:00 (CST) Central Standard Time', value: 'CST', icon: '' },
            { title: 'UTC-5:00 (EST) Eastern Standard Time', value: 'EST', icon: '' },
            { title: 'UTC-4:00 (AST) Atlantic Standard Time', value: 'AST', icon: '' },
            { title: 'UTC-3:00 (BRT) Brasilia Time', value: 'BRT', icon: '' },
            { title: 'UTC-2:00 (FNT) Fernando de Noronha Time', value: 'FNT', icon: '' },
            { title: 'UTC-1:00 (AZOT) Azores Standard Time', value: 'AZOT', icon: '' },
            { title: 'UTC+0:00 (GMT) Greenwich Mean Time', value: 'GMT', icon: '' },
            { title: 'UTC+1:00 (CET) Central European Time', value: 'CET', icon: '' },
            { title: 'UTC+2:00 (EET) Eastern European Time', value: 'EET', icon: '' },
            { title: 'UTC+3:00 (MSK) Moscow Standard Time', value: 'MSK', icon: '' },
            { title: 'UTC+4:00 (GET) Georgia Standard Time', value: 'GET', icon: '' },
            { title: 'UTC+5:00 (PKT) Pakistan Standard Time', value: 'PKT', icon: '' },
            { title: 'UTC+6:00 (BST) Bangladesh Standard Time', value: 'BST', icon: '' },
            { title: 'UTC+7:00 (ICT) Indochina Time', value: 'ICT', icon: '' },
            { title: 'UTC+8:00 (HKT) Hong Kong Time', value: 'HKT', icon: '' },
            { title: 'UTC+9:00 (JST) Japan Standard Time', value: 'JST', icon: '' },
            { title: 'UTC+10:00 (AEST) Australian Eastern Standard Time', value: 'AEST', icon: '' },
            { title: 'UTC+11:00 (VLAT) Vladivostok Time', value: 'VLAT', icon: '' },
            { title: 'UTC+12:00 (NZST) New Zealand Standard Time', value: 'NZST', icon: '' },
            { title: 'UTC+13:00 (TOT) Tonga Time', value: 'TOT', icon: '' },
            { title: 'UTC+14:00 (LINT) Line Islands Time', value: 'TOT', icon: '' },
        ];
    }
    static getcatalogSourceOptions(): SelectFilterOption[] {
        return [
            { title: $localize`My academy`, value: 'customer', icon: 'folder_shared' },
            { title: $localize`KOL`, value: 'public', icon: 'folder_special' },
            { title: $localize`All`, value: 'publicOrCustomer', icon: 'library_books' },
        ];
    }
    static getNotesScopeOptions(): SelectFilterOption[] {
        return [
            {
                title: $localize`:@@user:User`,
                value: 2,
                icon: 'person',
            },
            {
                title: $localize`:@@client:Client`,
                value: 13,
                icon: 'supervised_user_circle',
            },
            {
                title: $localize`:@@task:Task`,
                value: 27,
                icon: 'task',
            },
            {
                title: $localize`:@@resorce:Resource`,
                value: 1,
                icon: 'category',
            },
            {
                title: $localize`:@@general:General`,
                value: 'null',
                icon: 'dashboard',
            },
        ];
    }
    static getfunctionOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Course`, value: 3, icon: 'kd-icon-task', isMainResource: true },
            {
                title: $localize`:@@user:User`,
                value: 2,
                icon: 'person',
            },
            {
                title: $localize`:@@group:Group`,
                value: 12,
                icon: 'groups',
            },
            { title: $localize`Survey`, value: 4, icon: 'kd-icon-survey', isMainResource: true },
            {
                title: $localize`:@@category:Category`,
                value: 11,
                icon: 'folder',
            },
            {
                title: $localize`:@@tag:Tag`,
                value: 10,
                icon: 'tag',
            },
            { title: $localize`Test`, value: 5, icon: 'kd-icon-test', isMainResource: true },
            {
                title: $localize`:@@customer:Academy`,
                value: 13,
                icon: 'supervised_user_circle',
            },
            {
                title: $localize`:@@section:Section`,
                value: 9,
                icon: 'widgets',
            },
            { title: $localize`E-course`, value: 6, icon: 'kd-icon-player', isMainResource: true },
            {
                title: $localize`:@@folder:Folder`,
                value: 8,
                icon: 'folder_open',
            },
            {
                title: $localize`:@@scorm:SCORM`,
                value: 20,
                icon: 'business_center',
            },
            { title: $localize`Material`, value: 7, icon: 'kd-icon-document', isMainResource: true },
            {
                title: $localize`:@@answer:Answer`,
                value: 16,
                icon: 'fact_check',
            },
            {
                title: $localize`:@@alternative:Alternative`,
                value: 15,
                icon: 'alt_route',
            },
            {
                title: $localize`:@@task:Task`,
                value: 17,
                icon: 'task',
            },
            {
                title: $localize`:@@question:Question`,
                value: 14,
                icon: 'contact_support',
            },
        ];
    }
    static getUserRoleOptions(): SelectFilterOption[] {
        return [
            {
                title: $localize`Master Admin`,
                value: 'superAdmin',
                icon: 'admin_panel_settings',
                class: 'material-icons-outlined',
            },
            { title: $localize`Main Admin`, value: 'customerAdmin', icon: 'groups' },
            { title: $localize`Group Admin`, value: 'groupAdmin', icon: 'group' },
            { title: $localize`Learner`, value: 'user', icon: 'person' },
        ];
    }
    static getCompetenceStatusOptions(): SelectFilterOption[] {
        return [
            {
                title: $localize`Completed`,
                value: 'green',
                icon: 'circle',
                class: 'green-circle-comp',
            },
            { title: $localize`Not completed`, value: 'red', icon: 'circle', class: 'red-circle-comp' },
            { title: $localize`About to expire`, value: 'iceblue', icon: 'circle', class: 'blue-circle-comp' },
        ];
    }

    static getTaskActionsOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Call`, value: 'call', icon: 'call' },
            { title: $localize`Email`, value: 'emal', icon: 'mail' },
            { title: $localize`Follow up`, value: 'follow', icon: 'summarize' },
            { title: $localize`Zoom`, value: 'zoom', icon: 'groups_3' },
            { title: $localize`Other`, value: 'other', icon: 'dynamic_feed' },
            { title: $localize`Demo`, value: 'demo', icon: 'co_present' },
        ];
    }
    static getTaskTypesOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Client`, value: 'client', icon: 'business' },
            { title: $localize`Offerers/Demo`, value: 'offer', icon: 'gpp_good' },
            { title: $localize`Prospect`, value: 'prospect', icon: 'gpp_maybe' },
            { title: $localize`Nothanker`, value: 'nothanker', icon: 'gpp_bad' },
            { title: $localize`Contact`, value: 'contact', icon: 'person' },
            { title: $localize`Ex-Client`, value: 'exclient', icon: 'block' },
            { title: $localize`Partner`, value: 'partner', icon: 'handshake' },
            { title: $localize`Other`, value: 'other', icon: 'dynamic_feed' },
        ];
    }
    static getTaskDoneOptions(): SelectFilterOption[] {
        return [
            { title: $localize`All`, value: 'null', icon: 'rule' },
            { title: $localize`Not done`, value: 'false', icon: 'cancel' },
            { title: $localize`Done`, value: 'true', icon: 'check_circle' },
        ];
    }
    static getMyTasksOptions(): SelectFilterOption[] {
        return [
            { title: $localize`All Tasks`, value: 'all', icon: 'groups' },
            { title: $localize`Public Tasks`, value: 'public', icon: 'public' },
            { title: $localize`Owned/Assigned Tasks`, value: 'owner_assignee', icon: 'badge' },
            { title: $localize`Owned Tasks`, value: 'owner', icon: 'assignment_ind' },
            { title: $localize`Assigned Tasks`, value: 'assigee', icon: 'assignment_return' },
        ];
    }

    static getTaskPrioOptions(): SelectFilterOption[] {
        return [
            { title: $localize`A1`, value: 'a1', icon: 'electric_bolt' },
            { title: $localize`A2`, value: 'a2', icon: 'electric_bolt' },
            { title: $localize`A3`, value: 'a3', icon: 'electric_bolt' },
            { title: $localize`B1`, value: 'b1', icon: 'electric_bolt' },
            { title: $localize`B2`, value: 'b2', icon: 'electric_bolt' },
            { title: $localize`B3`, value: 'b3', icon: 'electric_bolt' },
            { title: $localize`C1`, value: 'c1', icon: 'electric_bolt' },
            { title: $localize`C2`, value: 'c2', icon: 'electric_bolt' },
            { title: $localize`C3`, value: 'c3', icon: 'electric_bolt' },
        ];
    }
    static getResApprovalStatusOptions(): SelectFilterOption[] {
        return [
            {
                title: $localize`Approved`,
                value: 'approved',
                icon: 'assignment_turned_in',
                class: 'approval-status-approved',
            },
            {
                title: $localize`Rejected`,
                value: 'rejected',
                icon: 'cancel',
                class: 'approval-status-rejected',
            },
            {
                title: $localize`Pending`,
                value: 'pending',
                icon: 'assignment_turned_in',
                class: 'approval-status-pending',
            },
        ];
    }
    static getResourceStateOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Public`, value: 'public', icon: 'public' },
            { title: $localize`None Public`, value: 'not_public', icon: 'public_off' },
            {
                title: $localize`Published`,
                value: 'published',
                icon: 'published_with_changes',
                class: 'material-icons-outlined',
            },
            {
                title: $localize`Not on Publish`,
                value: 'not_last_published',
                icon: 'unpublished',
                class: 'material-icons-outlined',
            },
            { title: $localize`Not Published yet`, value: 'not_published', icon: 'unpublished' },
            { title: $localize`Deleted`, value: 'deleted', icon: 'auto_delete' },
            { title: $localize`Expired`, value: 'expired', icon: 'event_busy' },
        ];
    }

    static getItemSelectedOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Filter by selected`, value: '1', icon: 'done_all' },
            { title: $localize`Filter by unselected`, value: '0', icon: 'remove_done' },
        ];
    }

    static getInstructorTitlesOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Instructor`, value: 'instructor', icon: 'engineering' },
            { title: $localize`Teacher`, value: 'teacher', icon: 'engineering' },
            { title: $localize`Tutor`, value: 'tutor', icon: 'engineering' },
        ];
    }

    static getQsStyle(question: Question): {
        icon: string;
        color: string;
        label?: string;
        questionNameIconColor?: string;
    } {
        switch (question.question_type_value) {
            case 'radio':
                return {
                    icon: question.user_answer ? 'check_circle' : 'cancel',
                    color: question.user_answer ? '#93cbd1' : '#b2b2b2',
                    questionNameIconColor: question.user_answer ? '#93cbd1' : '#b2b2b2',
                };
            case 'checkbox':
                return {
                    icon: question.user_answer ? 'check_box' : 'disabled_by_default',
                    color: question.user_answer ? '#93cbd1' : '#b2b2b2',
                    questionNameIconColor: question.user_answer ? '#93cbd1' : '#b2b2b2',
                };
            case 'input':
                return {
                    icon: 'edit_note',
                    color: !question.metadata ? '#b2b2b2' : question.metadata.verifier === 'ai' ? '#93cbd1' : '#b2b2b2',
                    questionNameIconColor: !question.metadata
                        ? '#dddddd'
                        : question.metadata.correct
                        ? '#93cbd1'
                        : '#b2b2b2',
                    label: !question.metadata
                        ? $localize`Not reviewed yet`
                        : question.metadata.verifier === 'ai'
                        ? $localize`Reviewed by AI`
                        : $localize`Reviewed by Admin`,
                };
        }
    }
    static getResourcePermissions(): {
        index: number;
        value: string;
        label: string;
        class: string;
        tooltip?: string;
        disableUser?: boolean;
    }[] {
        return [
            {
                index: 0,
                value: 'view',
                label: $localize`View`,
                class: 'res-perm-view',
                tooltip: $localize`View`,
                disableUser: false,
            },
            {
                index: 1,
                value: 'mandatory',
                label: $localize`Mandatory`,
                class: 'res-perm-mandatory',
                tooltip: $localize`Mandatory`,
                disableUser: false,
            },
            {
                index: 2,
                value: 'enroll_approval_required',
                label: $localize`Enroll`,
                class: 'res-perm-enroll_approval_required',
                tooltip: $localize`Which learners need approvals for enroll in course.`,
                disableUser: false,
            },
            {
                index: 3,
                value: 'signoff_required',
                label: $localize`Signoff`,
                class: 'res-perm-signoff_required',
                tooltip: $localize`Which learners need to signoff after finishing the course.`,
                disableUser: false,
            },
            {
                index: 4,
                value: 'edit',
                label: $localize`Edit`,
                class: 'res-perm-edit',
                tooltip: $localize`Edit`,
                disableUser: true,
            },
            {
                index: 5,
                value: 'publish',
                label: $localize`Publish`,
                class: 'res-perm-publish',
                tooltip: $localize`Who can publish`,
                disableUser: true,
            },
            {
                index: 6,
                value: 'tutor',
                label: $localize`Tutor`,
                class: 'res-perm-enroll',
                tooltip: $localize`Which admins can review the enrollment requests of the learners`,
                disableUser: true,
            },
            {
                index: 7,
                value: 'review',
                label: $localize`Review`,
                class: 'res-perm-review',
                tooltip: $localize`Which admins can review the signoff and other results of the learners`,
                disableUser: true,
            },
        ];
    }
    static getMessageScopesOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Case`, value: 'case', icon: 'school' },
            { title: $localize`Chat`, value: 'dialog', icon: 'forum' },
            { title: $localize`Report`, value: 'report', icon: 'warning_amber' },
            { title: $localize`Important`, value: 'important', icon: 'comment_bank' },
            { title: $localize`Unread`, value: 'unread', icon: 'mark_unread_chat_alt' },
            { title: $localize`Validation`, value: 'validation', icon: 'mark_chat_read' },
        ];
    }

    static getLLmsList(): { name: string; value: string; enabled: boolean }[] {
        return [
            { name: 'GPT-4o', value: 'gpt-4o', enabled: true },
            { name: 'ChatGPT-4 Turbo', value: 'gpt-4-turbo', enabled: true },
            { name: 'ChatGPT-3.5 Turbo', value: 'gpt-3.5-turbo', enabled: true },
            { name: 'ChatGPT-4', value: 'gpt-4', enabled: true },
            { name: 'Bing', value: 'bing', enabled: false },
            { name: 'Claude-1', value: 'claude-1', enabled: false },
            { name: 'Claude-2', value: 'claude-2', enabled: false },
            { name: 'Claude-31', value: 'claude-31', enabled: false },
            { name: 'Coral', value: 'coral', enabled: false },
            { name: 'Falcon', value: 'falcon', enabled: false },
            { name: 'Gab New', value: 'gab-new', enabled: false },
            { name: 'Gemini', value: 'gemini', enabled: false },
            { name: 'Gemini Advanced New', value: 'gemini-advanced-new', enabled: false },
            { name: 'GPT-Instruct', value: 'gpt-instruct', enabled: false },
            { name: 'Grok', value: 'grok', enabled: false },
            { name: 'Grok (Fun Mode)', value: 'grok-fun-mode', enabled: false },
            { name: 'Llama-2', value: 'llama-2', enabled: false },
            { name: 'Mistral', value: 'mistral', enabled: false },
            { name: 'Mixtral', value: 'mixtral', enabled: false },
            { name: 'Perplexity', value: 'perplexity', enabled: false },
        ];
    }

    static getMedia(url: string): Media {
        return (
            this.getAssetsImg(url) ||
            this.getYoutubeMedia(url) ||
            this.getVimeoMedia(url) ||
            this.getWebsiteMedia(url) ||
            this.getLinkMedia(url)
        );
    }

    static isVideoUrl(url: string): boolean {
        return this.isYoutubeURL(url) || this.isVimeoURL(url) || !!this.internalLink(url);
    }

    static internalLink(url: string) {
        return url.match(/.+?\.k3\.io/) || url.match(/.+?\.klickdata\.se/);
    }

    static isUrl(url: string) {
        const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return regexp.test(url);
    }

    /**
     * Handle category parent/children titles.
     * @param categories Categories
     * @returns Resource category array.
     */
    static mapParentCategories<T extends ParentMapper<T>>(categories: T[]): T[] {
        const categoryMap = new Map<Number, T>();
        const orphans: T[] = [];
        categories.forEach((cat) => {
            if (cat.parent_id === null && !categoryMap.has(cat.id)) {
                cat.loadedChildren = []; // initialized children array
                categoryMap.set(cat.id, cat); // add parent to map, if not yet added.
            } else if (cat.parent_id != null && categoryMap.has(cat.parent_id)) {
                const parent = categoryMap.get(cat.parent_id);
                this.addUniqueItem(parent.loadedChildren, cat);
            } else if (cat.parent_id != null) {
                // add categories his parent not yet added o orphans.
                this.addUniqueItem(orphans, cat);
            }
        });

        for (let i = orphans.length; i--; ) {
            const cat = orphans[i];
            const parent = categoryMap.get(cat.parent_id);
            if (parent) {
                this.addUniqueItem(parent.loadedChildren, cat);
                orphans.splice(i, 1);
            }
        }

        // const results = Array.from(categoryMap.values()).concat(orphans);
        const results: T[] = [];
        categoryMap.forEach((cat) => {
            // results.push(cat); // Stop adding parent and just map children with parents
            cat.loadedChildren.forEach((child) => {
                child.title = `${cat.title}/${child.title}`;
                results.push(child);
            });
            cat.loadedChildren = null; // Remove children array.
        });
        return results.concat(orphans);
    }

    /**
     * Ensure not duplication on children array
     * @param items Array than contains unique items.
     * @param item Item to be add to array ignore duplication.
     */
    static addUniqueItem<T extends { id: number }>(items: T[], item: T) {
        const index = items.findIndex((child) => child.id === item.id);
        if (index !== -1) {
            items.splice(index, 1);
        }
        items.push(item);
    }

    /**
     * Check the equality of two objects.
     * @param a The first object.
     * @param b the second object.
     * @returns true when every key-value in equal same value in b
     */
    private static objEqual<T extends {}>(a: T, b: T): boolean {
        if (Object.keys(a).length !== Object.keys(b).length) {
            return false;
        }

        return Object.keys(a).every((key) => Utils.isEqual(a[key], b[key]));
    }

    /**
     * Check the equality of two objects.
     * @param a The first object.
     * @param b the second object.
     * @returns true when every key-value in equal same value in b
     */
    public static isEqual<T>(a: T, b: T, equals: (a: T, b: T) => boolean = (a, b) => a === b): boolean {
        if (a === b) {
            return true;
        }
        if (a == null || b == null) {
            return false;
        }

        if (typeof a === 'string' && typeof b === 'string') {
            return Utils.stringEquals(a, b);
        }

        if (Utils.isBoolean(a) && Utils.isBoolean(b)) {
            return Utils.booleanFrom(a) === Utils.booleanFrom(b);
        }

        if (Array.isArray(a) && Array.isArray(b)) {
            return Utils.arraysEqual(a, b);
        }

        if (typeof a === 'object' && typeof b === 'object') {
            return Utils.objEqual(a, b);
        }

        return equals(a, b);
    }

    private static stringEquals(a: string, b: string) {
        return a.toLowerCase() === b.toLowerCase();
    }

    private static isBoolean(value: any): boolean {
        if (typeof value === 'string') {
            const lowerCaseValue = value.toLowerCase();
            return (
                lowerCaseValue === 'true' ||
                lowerCaseValue === '1' ||
                lowerCaseValue === 'false' ||
                lowerCaseValue === '0'
            );
        }

        return value === true || value === false || value === 1 || value === 0;
    }

    private static booleanFrom(value: any): boolean {
        if (typeof value === 'string') {
            const lowerCaseValue = value.toLowerCase();
            if (lowerCaseValue === 'true' || lowerCaseValue === '1') {
                return true;
            } else if (lowerCaseValue === 'false' || lowerCaseValue === '0') {
                return false;
            }
        } else if (typeof value === 'number') {
            return value === 1;
        } else if (typeof value === 'boolean') {
            return value;
        }

        return false;
    }

    /**
     * Check if two filter arrays are equal.
     * @param f1 - First filter array.
     * @param f2 - Second filter array.
     * @returns {boolean} - True if equal, false otherwise.
     */
    public static modelArrayEquals<T extends IModel>(f1: T[], f2: T[]): boolean {
        if (!f1 || !f2) {
            return false;
        }

        if (f1.length !== f2.length) {
            return false;
        }

        return f1.every((m1) => {
            const m2 = f2.find((m2) => m2.id === m1.id);
            return Utils.objEqual(m1, m2);
        });
    }

    /**
     * Check the equality of two arrays.
     * @param a The first T array.
     * @param b the second T array.
     * @param sort boolean consider array sorting.
     * @param equals condition default is aItem === bItem, but you can overide the equility condition by closure callback.
     *
     * Example: `(ai, bi) => ai.id === bi.id` => equals when id in a item equals id in b item.
     *
     * @returns true when two arrays are equals ignoring order, false otherwise.
     */
    public static arraysEqual<T>(
        a: T[],
        b: T[],
        sort = true,
        equals: (ai: T, bi: T) => boolean = (ai, bi) => ai === bi
    ): boolean {
        if (a === b) {
            return true;
        }
        if (a == null || b == null) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }

        if (sort) {
            a.sort();
            b.sort();
        }
        for (let i = 0; i < a.length; ++i) {
            if (!equals(a[i], b[i])) {
                return false;
            }
        }
        return true;
    }

    // loop over b, add the elements of a if it doesn't exist in a
    public static mergeUnique<T>(a: T[], b: T[], cond: (ai: T, bi: T) => boolean): T[] {
        return b.reduce(
            (acc, bItem) => {
                const index = a.findIndex((aItem) => cond(aItem, bItem));
                if (index === -1) {
                    acc.push(bItem);
                }
                return acc;
            },
            [...a]
        ); // initialize the new Array with the contents of array1
    }

    public static isEmpty(value: any, nullable = false) {
        if (value == null) {
            return !nullable;
        }
        if (Array.isArray(value)) {
            return !value.filter((item) => !Utils.isEmpty(item, nullable)).length;
        }
        if (typeof value === 'object') {
            return !ObjectValidator.isValid(value, [], nullable);
        }

        if (typeof value === 'string') {
            return !value.trim().length;
        }

        if (typeof value === 'boolean') {
            return false;
        }
        return !value;
    }

    public static arrayIsNulled(value: any) {
        return Array.isArray(value) && !!value.length && value.every((item) => item === null);
    }

    /**
     * Sync model many relation.
     * @param value model value
     * @returns
     */
    public static modelSync(value: ModelSync | ModelSync[]): any {
        if (Array.isArray(value)) {
            const res = value.filter(Utils.modelSyncItem);
            return !Utils.isEmpty(res) ? res : undefined;
        }
        return Utils.modelSyncItem(value);
    }

    private static modelSyncItem(value: ModelSync): any {
        return ObjectValidator.isValidKeys(value, ['id'], ['sync_all', 'attach_ids', 'detach_ids']) ? value : undefined;
    }

    public static resetModelSync(value: ModelSync, id: number): {} {
        const isDirty = ObjectValidator.isDirty(value, ['sync_all', 'attach_ids', 'detach_ids']);
        return {
            ...value,
            id: isDirty ? id : null,
            sync_all: null,
            attach_ids: [],
            detach_ids: [],
        };
    }

    public static nullableSync(value: { [key: string]: string }) {
        return !Utils.isEmpty(value) ? value : undefined;
    }

    public static mediasPayload(value: { [key: string]: string }) {
        return !Utils.isEmpty(value, true) ? value : undefined;
    }

    /**
     * Check if two filter arrays are equal.
     * @param f1 - First filter array.
     * @param f2 - Second filter array.
     * @returns {boolean} - True if equal, false otherwise.
     */
    public static filtersEqual<T>(c1: FilterCollection<T>, c2: FilterCollection<T>): boolean {
        if (!c1 || !c2) {
            return false;
        }

        const f1 = c1.filters;
        const f2 = c2.filters;

        if (!f1 || !f2) {
            return false;
        }

        if (f1.length !== f2.length) {
            return false;
        }

        return f1.every((item1) =>
            Utils.filterEqual(
                item1,
                f2.find((item2) => item2.property === item1.property)
            )
        );
    }

    /**
     * Check if two filters are equal.
     * @param f1 - First filter.
     * @param f2 - Second filter.
     * @returns {boolean} - True if equal, false otherwise.
     */
    public static filterEqual<T>(f1: Filter<T>, f2: Filter<T>): boolean {
        if (!f1 || !f2) {
            return false;
        }

        if (f1.property !== f2.property) {
            return false;
        }

        if (!f1.items || !f2.items) {
            return false;
        }

        if (f1.items.length !== f2.items.length) {
            return false;
        }

        return Utils.arraysEqual(f1.items, f2.items);
    }

    /**
     In the wrapInArray function, it checks if the input value is an array using Array.isArray(). If it's an array, 
     * the function returns the input value as is. If it's not an array, the function wraps the value inside a new array before returning it.
     * @param value 
     * @returns 
     */
    public static wrapInArray<T>(value: T | T[]): T[] {
        return Array.isArray(value) ? value : [value];
    }

    public static getSubdomain(): string {
        const host = window.location.host;
        return host.match(/^(?:https?:\/\/)?(?:www\.)?([A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?)\./)?.[1] ?? null;
    }

    public static routeWithWindowLocationAssign(targetLocation: string) {
        if (targetLocation) {
            const baseLocation = window.location.href.split('?')[0].replace(window.location.pathname, '');
            window.location.assign(`${baseLocation}${targetLocation}`);
        }
    }

    public static getDomain(): string {
        const host = window.location.host.split(':')[0];
        // const parts = host.split('.');
        // // is there a subdomain?
        // // while (parts.length > 2 || (parts.findIndex(p => p.indexOf('localhost') !== -1) !== -1 && parts.length > 1)) {
        // if (parts.length > 2) {
        //     parts.shift();
        // }
        // const domain = parts.join('.');

        // Use a regular expression to extract the main domain
        // The i flag in the regular expression makes it case-insensitive.
        const domainMatch = host.match(/(?:www\.)?(.*?)\.(io|se|net)$/i);
        return domainMatch ? domainMatch[1] : null;
    }

    /**
     * Get all activated route snapshot param under lazy load modules
     * @see RouterModule.forChild(routes)
     */
    public static getSnapshotParams(route: ActivatedRoute): { [key: string]: number } {
        const params: { [key: string]: number } = {};
        do {
            const values = route.snapshot.params;
            Object.keys(values).forEach((key) => (params[key] = values[key]));
            route = route.parent;
        } while (route);
        return params;
    }

    /**
     * Get all activated route stream observable param under lazy load modules
     * @see RouterModule.forChild(routes)
     * @see Observable
     */
    public static getRouteParam(route: ActivatedRoute, routeKey: string): Observable<number> {
        const obs: Observable<Params>[] = [];
        do {
            obs.push(route.params);
            route = route.parent;
        } while (route);
        return merge(...obs).pipe(
            filter((param) => !!param[routeKey]),
            map((param) => param[routeKey])
        );
    }

    public static getSelectedMobileTabsOption(router: Router, activityOptions: any[]): any {
        return activityOptions.map((option) => option.value).includes(router.url.split('/').pop())
            ? activityOptions.find((option) => option.value === router.url.split('/').pop())
            : activityOptions[0];
    }

    /**
     * Capitalize first letter
     */
    public static capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Check if prompt is done
     */
    public static changedControls<T extends IDataModel>(formGroup: FormGroup): T {
        const formData = {};
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (!control.pristine) {
                formData[controlName] = control.value;
            }
        });
        return <T>formData;
    }

    /**
     * Check if prompt is done
     */
    public static getHeadline(body: string): string {
        const text = Utils.convertToPlain(body);
        const firstSentence = text.split(/[.،,\n:]/u, 2)[0] || text;
        const firstWords = Utils.words(text, 8);
        const titles = [firstSentence, firstWords].sort();
        return titles[0];
    }

    public static convertToPlain(html: string) {
        const tempDivElement = document.createElement('div');
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || '';
    }

    public static words(text: string, n: number): string {
        const words = text.split(' ');
        const firstNWords = words.slice(0, n).join(' ');
        return firstNWords;
    }

    public static getOwlOptions(customOption: OwlOptions = {}): OwlOptions {
        return {
            lazyLoadEager: 2,
            loop: false,
            mouseDrag: true,
            touchDrag: true,
            pullDrag: true,
            dots: false,
            navSpeed: 700,
            autoWidth: true,
            navText: [
                '<img src="assets/images/arrow.svg" width="20">',
                '<img src="assets/images/arrow.svg" width="20">',
            ],
            nav: true,
            ...customOption,
        };
    }
    public static getFixedTimePeriods() {
        return [
            { days: 1, value: 'daily', label: $localize`Daily`, abbr: 'DLY' },
            { days: 7, value: 'weekly', label: $localize`Weekly`, abbr: 'WLY' },
            { days: 30, value: 'monthly', label: $localize`Monthly`, abbr: 'MLY' },
            { days: 90, value: 'quarterly', label: $localize`Quarterly`, abbr: 'QTR' },
            { days: 120, value: 'tertial', label: $localize`Tertial`, abbr: 'TRI' },
            { days: 180, value: 'half_yearly', label: $localize`Half-yearly`, abbr: 'HYR' },
            { days: 365, value: 'yearly', label: $localize`Yearly`, abbr: 'YLY' },
        ];
    }
    public static getAccessControlPermType(type?: string) {
        const perms = [
            {
                type: 'enroll_approval_required',
                title: $localize`Approval for enroll`,
                icon: 'play_circle',
            },
            {
                type: 'mandatory',
                title: $localize`Mandatory`,
                icon: 'priority_high',
            },
            {
                type: 'view',
                title: $localize`Visibility`,
                icon: 'preview',
            },
            {
                type: 'signoff_required',
                title: $localize`Approval /Sign off`,
                icon: 'assignment_turned_in',
            },
        ];
        return type ? perms.find((perm) => perm.type == type) : perms;
    }
    /**
     * Convert a number of days into the corresponding string (e.g., daily, weekly)
     * @param days recurring repeat_every number of days
     * @returns
     */
    public static daysToString(days: number): string {
        switch (days) {
            case 0:
                return 'null';
            case 1:
                return 'daily';
            case 7:
                return 'weekly';
            case 30:
                return 'monthly';
            case 90:
                return 'quarterly';
            case 180:
                return 'half_yearly';
            case 365:
                return 'yearly';
            case 730:
                return '2years';
            case 1826:
                return '5years';
            default:
                return 'custom'; // If it's a custom number of days
        }
    }

    /**
     * Convert the recurring string into the corresponding number of days
     * @param recurringStr
     * @returns
     */
    public static stringToDays(recurringStr: string): number {
        switch (recurringStr) {
            case 'daily':
                return 1;
            case 'weekly':
                return 7;
            case 'monthly':
                return 30;
            case 'quarterly':
                return 90;
            case 'half_yearly':
                return 180;
            case 'yearly':
                return 365;
            case '2years':
                return 730;
            case '5years':
                return 1826;
            default:
                return 1; // Default value for unrecognized or custom input
        }
    }

    // Check if expiring soon based on the recurring period
    public static isExpiringSoon(recurringPeriod: number, done_at: string): boolean {
        const now = moment(); // Current date
        const expiryDate = Utils.calculateExpiry(recurringPeriod, done_at); // Calculate expiry based on done date and recurring period

        if (!expiryDate) {
            return false; // Return false if expiryDate is invalid
        }

        const daysRemaining = now.diff(expiryDate, 'days'); // Calculate days remaining (negative if overdue)

        // Define expiration soon based on different recurring periods
        if (recurringPeriod <= 30) {
            // Monthly
            return daysRemaining <= 7; // Expiring within 1 week
        } else if (recurringPeriod <= 90) {
            // Quarterly
            return daysRemaining <= 14; // Expiring within 2 weeks
        } else if (recurringPeriod <= 182) {
            // Half-yearly
            return daysRemaining <= 14; // Expiring within 2 weeks
        } else if (recurringPeriod <= 365) {
            // Yearly
            return daysRemaining <= 30; // Expiring within 1 month
        } else if (recurringPeriod > 365 && recurringPeriod <= 730) {
            // 1-2 years
            return daysRemaining <= 60; // Expiring within 2 months
        } else if (recurringPeriod > 730 && recurringPeriod <= 1826) {
            // 2-5 years
            return daysRemaining <= 180; // Expiring within 6 months
        }

        return false; // Default: not expiring soon
    }

    // Check if overdue
    public static isOverdue(recurringPeriod: number, done_at: string): boolean {
        const expiryDate = Utils.calculateExpiry(recurringPeriod, done_at);

        // Return true if today's date is greater than the calculated expiry date, otherwise false
        return expiryDate ? moment().isAfter(expiryDate) : false;
    }

    // Calculate expiry based on the recurring period and done date
    public static calculateExpiry(
        recurringPeriod: number | string,
        done_at: string | moment.Moment
    ): moment.Moment | null {
        const startDate = moment(done_at, 'YYYY-MM-DD HH:mm:ss', true); // Parse done date with time

        // Return null if the date is invalid
        if (!startDate.isValid()) {
            return null;
        }

        // Define the next expiry date based on the recurring period
        let expiryDate: moment.Moment;

        switch (recurringPeriod) {
            case 30: // Monthly
                expiryDate = startDate.clone().startOf('month').add(1, 'month');
                break;

            case 90: // Quarterly
                expiryDate = Utils.getQuarterExpiry(startDate);
                break;

            case 182:
            case 183: // Half-yearly
                expiryDate = Utils.getHalfYearExpiry(startDate);
                break;

            case 365:
            case 366: // Yearly
                expiryDate = startDate.clone().add(1, 'year').startOf('year');
                break;

            case 1825:
            case 1826: // 5 years
                expiryDate = startDate.clone().add(5, 'years').startOf('year');
                break;

            default: // Custom or longer periods
                expiryDate = startDate.clone().add(recurringPeriod, 'days');
                break;
        }

        return expiryDate; // Return the calculated expiry date
    }

    // Helper function to calculate the 1st of the next quarter
    private static getQuarterExpiry(startDate: moment.Moment): moment.Moment {
        const month = startDate.month() + 1; // moment months are 0-based

        if (month <= 3) {
            return moment({ year: startDate.year(), month: 3, day: 1 }); // 1st of April
        } else if (month <= 6) {
            return moment({ year: startDate.year(), month: 6, day: 1 }); // 1st of July
        } else if (month <= 9) {
            return moment({ year: startDate.year(), month: 9, day: 1 }); // 1st of October
        } else {
            return moment({ year: startDate.year() + 1, month: 0, day: 1 }); // 1st of January (next year)
        }
    }

    // Helper function to calculate the 1st of the next half-year
    private static getHalfYearExpiry(startDate: moment.Moment): moment.Moment {
        const month = startDate.month() + 1;

        if (month <= 6) {
            return moment({ year: startDate.year(), month: 6, day: 1 }); // 1st of July
        } else {
            return moment({ year: startDate.year() + 1, month: 0, day: 1 }); // 1st of January (next year)
        }
    }

    // Method to handle occasion label
    public static handleOccasionLabel(data: {
        done_at?: string | null;
        recurring?: CompetenceRecurring | null;
        occasion_status?: string;
    }): string | null {
        if (!data || !data.done_at) {
            return '';
        }

        const done = moment(data.done_at);
        if (done.format('YYYY') == '1970') {
            return '';
        }

        const doneMonth = done.month() + 1; // Month is 0-based in moment
        const doneYear = done.year();
        let occasionLabel: string | null = null;

        switch (data.recurring.recurring) {
            case '180': // Half-yearly
                occasionLabel = doneMonth <= 6 ? 'H1' : 'H2';
                break;
            case '120': // Tertial
                occasionLabel = doneMonth <= 4 ? 'T1' : doneMonth <= 8 ? 'T2' : 'T3';
                break;
            case '90': // Quarterly
                occasionLabel = doneMonth <= 3 ? 'Q1' : doneMonth <= 6 ? 'Q2' : doneMonth <= 9 ? 'Q3' : 'Q4';
                break;
            case '30': // Monthly
                occasionLabel = done.format('MMM').substring(0, 2); // Get first 2 letters of the month
                break;
            case '7': // Weekly
                occasionLabel = 'W' + done.week();
                break;
            case '1': // Daily
                occasionLabel = 'D' + done.dayOfYear();
                break;
            case '365': // Yearly
            default:
                occasionLabel = doneYear.toString().substring(2); // Last two digits of the year
                break;
        }

        return occasionLabel;
    }

    // Method to calculate occasion status
    public static getOccasionStatus(data: {
        done_at?: string | null;
        recurring?: CompetenceRecurring | null;
        occasion_status?: string;
    }): string {
        if (!data || !data.done_at) {
            return 'red';
        }

        const done = moment(data.done_at);

        if (data.done_at && done.format('YYYY') == '1970') {
            return 'green';
        }

        let occasionStatus: string = 'red'; // Default status
        const recurring = data.recurring?.recurring;

        if (data.done_at) {
            if (recurring) {
                const isOverdue = Utils.isOverdue(parseInt(recurring), data.done_at);
                if (isOverdue) {
                    occasionStatus = 'whiteonred'; // Done but overdue
                } else if (Utils.isExpiringSoon(parseInt(recurring), data.done_at)) {
                    occasionStatus = 'iceblue'; // Done but expiring soon
                } else {
                    occasionStatus = 'green'; // Done and certified
                }
            } else {
                occasionStatus = 'green'; // Done and no expiration
            }
        } else {
            // Not done yet
            if (data.occasion_status === 'completed') {
                occasionStatus = 'orange'; // Learner completed
            } else {
                occasionStatus = 'red'; // Not done and overdue
            }
        }

        return occasionStatus;
    }
}
