import { Cast, IDataModel, Ignore, InitialValue, Model } from '@klickdata/core/application';
import {
    AfterModelInit,
    CastType,
    MethodType,
    ModelSync,
    Nullable,
    SortedArray,
} from '@klickdata/core/application/src/model/model-interface';
import { CustomField } from '@klickdata/core/custom-field';
import { Customer } from '@klickdata/core/customer';
import { Media } from '@klickdata/core/media/src/media.model';
import { Notification } from '@klickdata/core/notification';
import { Resource, ResourceTagData } from '@klickdata/core/resource';
import { Downloads } from '@klickdata/core/resource/src/download.model';
import {
    AccessControlPermission,
    ApprovalMessage,
    Educator,
    Medias,
    ResourceData,
} from '@klickdata/core/resource/src/resource.model';
import { Filter, FilterModel, SelectFilterOption } from '@klickdata/core/table';
import { Task } from '@klickdata/core/task';
import { UserRole } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { WidgetData } from '@klickdata/core/widget';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Occasion } from './user-occasion/user-occasion.model';
import { PrivilegeType } from '@klickdata/core/util/src/type-util';

export type RoleValueType =
    | 'guest'
    | 'staff'
    | 'user'
    | 'groupadmin'
    | 'divisionadmin'
    | 'customeradmin'
    | 'masteradmin'
    | 'superadmin';

export interface Answers {
    opportunity_id: number;
    answer: string;
}

export interface UserOccasion {
    started_at?: string;
    occasionStatus?: string;
    end_date?: string;
    id?: number;
}

export interface NotificationSetting {
    type?: string;
    enabled?: boolean;
}

export const NotificationTypes = {
    new_message: {
        role_values: ['user', 'admin', 'superadmin'],
        label: $localize`New message`,
        description: $localize`Receive notifications for new messages`,
    },
    enrollment: {
        role_values: ['user', 'admin', 'superadmin'],
        label: $localize`Enrollment`,
        description: $localize`Receive notifications for enrollment updates`,
    },
    signoff: {
        role_values: ['admin', 'superadmin'],
        label: $localize`Signoff`,
        description: $localize`Receive notifications for signoff events`,
    },
    publish: {
        role_values: ['admin', 'superadmin'],
        label: $localize`Publish`,
        description: $localize`Receive notifications for new publishes`,
    },
    recommend: {
        role_values: ['user', 'admin', 'superadmin'],
        label: $localize`Recommendation`,
        description: $localize`Notify me with recommendation`,
    },
    newsletter: {
        role_values: ['user', 'admin', 'superadmin'],
        label: $localize`New letters`,
        description: $localize`I want to get KLMS monthly newsletter`,
    },
};

export type NotificationTypeKey = keyof typeof NotificationTypes;

export interface UserData extends IDataModel {
    id?: number;
    title?: string;
    notes?: string;
    username?: string;
    fname?: string;
    lname?: string;
    name?: string;
    email?: string;
    avatar?: string;
    alias?: string;
    position?: string;
    status?: number;
    disabled?: boolean;
    customer_id?: number;
    media_id?: number;
    media?: Media;
    customer?: Customer;
    role_value?: RoleValueType;
    end_date?: string;
    type?: string;
    verified?: boolean;
    activated?: boolean;
    created_by?: number;
    lang?: string;
    pref_lang_ids?: number[];
    employee_no?: string;
    use_manager_email?: boolean;
    manager_id?: string;
    phone?: string;
    created_at?: string;
    trial_ends_at?: string;
    updated_at?: string;
    deleted_at?: string;
    starting_date?: string;
    date_of_birth?: string;
    relation?: string;
    age_span?: string;
    gender?: string;
    opportunity_id?: number;
    permissions?: { [key: string]: string };

    sync_all_groups?: boolean;
    groups_attach?: number[];
    groups_detach?: number[];
    categories_attach?: number[];
    categories_detach?: number[];

    managed_groups?: number[];
    managed_divisions?: number[];
    cases_count?: number;
    author_cases_count?: number;
    last_seen?: string;
    about?: string;
    general_info?: string;
    references?: string[];
    section_ids?: number[];
    creator?: UserData;
    widgets?: WidgetData[];
    prompt_delimiters_disabled?: boolean;
    device_id?: string;

    /**
     * statistics
     */
    total_groups?: number;
    total_activities?: number;
    started_course_plan?: number;
    started_survey?: number;
    started_test?: number;
    started_course?: number;
    completed_course_plan?: number;
    completed_survey?: number;
    completed_test?: number;
    completed_course?: number;
    passed_test?: number;
    failed_test?: number;
    material_taken?: number;
    test_occasions?: number;
    course_occasions?: number;
    survey_occasions?: number;
    overall_time_spent?: number;
    learning_time_spent?: number;
    communications?: { [key: string]: any[] };

    /**
     * tools
     */
    submitText?: string;

    /**
     * User create - edit
     */
    groups?: {};
    group?: { [key: string]: any };
    resources?: ModelSync[];
    task?: any;
    staffRoleProperty?: string;
    newsletter?: boolean;
    staff?: { [key: string]: any };
    medias?: Medias;
    priority?: string;
    tag_ids?: number[];
    custom_fields?: CustomField[];
    message?: ApprovalMessage;
    resource_permissions?: AccessControlPermission[];
    notification_settings?: NotificationSetting[];
    group_id?: number;
    team_id?: number;
    source_id?: string;
}

export class User extends Model<UserData> implements FilterModel, AfterModelInit {
    id: number;
    username: string;
    fname: string;
    lname: string;
    name: string;
    email: string;
    avatar: string;
    alias: string;
    position: string;
    status: number;
    disabled: boolean;
    customer_id: number;
    media_id: number;
    role_value: RoleValueType;

    @Cast(CastType.MOMENT)
    @Nullable(MethodType.PUT)
    end_date: moment.Moment;

    type: string;
    @Ignore()
    verified: boolean;
    @Ignore()
    customer_published: boolean;
    @Ignore()
    activation_link: string;
    @Ignore()
    activated: boolean;
    @Ignore()
    created_by: number;
    lang: string; // siteLanguage
    pref_lang_ids: number[]; // Content languages
    employee_no: string;
    @Ignore()
    use_manager_email: boolean;
    manager_id: string;
    phone: string;
    title: string;
    notes: string;
    trial_ends_at: string;
    subscription_plan_id: number;
    @Cast(CastType.MOMENT)
    created_at: moment.Moment;
    @Cast(CastType.MOMENT)
    updated_at: moment.Moment;
    @Cast(CastType.MOMENT)
    deleted_at: moment.Moment;
    @Cast(CastType.MOMENT)
    date_of_birth: moment.Moment;
    @Cast(CastType.MOMENT)
    starting_date: moment.Moment;
    opportunity_id: number;
    total_groups: number;
    old_password: string;
    new_password: string;
    permissions: { [key: string]: string };
    test_occasions: number;
    course_occasions: number;
    survey_occasions: number;
    @InitialValue([])
    groups_attach: number[];
    @InitialValue([])
    groups_detach: number[];
    @InitialValue([])
    categories_attach: number[];
    @InitialValue([])
    categories_detach: number[];
    @InitialValue([])
    @SortedArray()
    references: string[];
    overall_time_spent: number;
    learning_time_spent: number;
    cases_count: number;
    author_cases_count: number;
    @Cast(CastType.MOMENT)
    last_seen: moment.Moment;
    about: string;
    general_info: string;
    age_span: string;
    gender: string;
    @InitialValue([])
    section_ids: number[];
    @Ignore()
    checked: boolean;
    relation: string;
    @Ignore()
    creator: UserData;
    widgets: WidgetData[];
    prompt_delimiters_disabled: boolean;
    @Ignore()
    occasions: Occasion[];
    @Ignore()
    answers: Answers[];
    @Cast(CastType.OBJECT)
    communications: { [key: string]: any[] };
    @Cast(CastType.OBJECT)
    address: {};
    tag_ids: number[];
    newsletter: boolean;
    @Ignore()
    customer: any;
    priority: string;
    @Ignore()
    managers: any;
    @Ignore()
    last_login: string;
    @Ignore()
    device_id: string;
    occasion: UserOccasion;

    /**
     * User create - edit
     */
    @Cast(CastType.CLOSURE, Utils.modelSync)
    groups?: ModelSync;
    @Cast(CastType.CLOSURE, Utils.modelSync)
    resources?: ModelSync[];

    task: Task;
    @Ignore()
    loading: boolean;
    @Ignore()
    tags: ResourceTagData[];
    @Ignore()
    staffRoleProperty: string;
    @Ignore()
    privileges: Privileges;

    @Cast(CastType.CLOSURE, Utils.mediasPayload)
    public medias: Medias;

    custom_fields: CustomField[];

    createdBy: Observable<User>;
    resource_permissions: AccessControlPermission[];
    notification_settings: NotificationSetting[];

    @Ignore()
    dashboard?: { [key: string]: boolean };
    @Ignore()
    all_resource_perm_checked: boolean;
    @Ignore()
    some_resource_perm_checked: boolean;
    @Nullable(MethodType.PUT)
    group_id: number;
    @Nullable(MethodType.PUT)
    team_id: number;
    @Ignore()
    competences?: Resource[];
    @Ignore()
    exercises?: Resource[];
    source_id: string;

    afterModelInit(data: UserData): void {
        if (!data.name) {
            this.name = this.fname && this.lname ? `${this.fname} ${this.lname}` : this.fname || this.lname || null;
        }
    }

    get hasActivities(): boolean {
        return this.dashboard && Object.values(this.dashboard).some((value) => value === true);
    }
    get finalResult(): Occasion {
        return Array.isArray(this.occasions) ? this.occasions[0] : this.occasions;
    }

    get userAndTaskTags(): ResourceTagData[] {
        const userTags = this.tags ?? [];
        const taskTags = this.task?.tags ?? [];

        return [...userTags, ...taskTags];
    }

    get signature(): string {
        const name =
            this.name ?? (this.fname && this.lname ? `${this.fname} ${this.lname}` : this.fname || this.lname || null);
        return name
            ?.match(/\b(\w)/g)
            ?.join('')
            .toUpperCase();
    }

    get finalAnswer(): Answers {
        return this.answers[0];
    }

    get isTrialEnded(): boolean {
        if (this.trial_ends_at) {
            return moment().isSameOrAfter(this.trial_ends_at);
        }
        return false;
    }

    get userAsEducator(): Educator {
        return <Educator>{
            name: this.name,
            id: this.id,
            email: this.email,
            phone: this.phone,
            about: this.about,
            title: this.title,
            media_id: this.media_id,
            media: this.media,
            task: this.task,
            communications: this.communications,
        };
    }

    get userRoleOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Main Admin`, value: 'customerAdmin', icon: 'groups' },
            { title: $localize`Group Admin`, value: 'groupAdmin', icon: 'group' },
            { title: $localize`User`, value: 'user', icon: 'person' },
        ];
    }

    get userStatusOptions(): SelectFilterOption[] {
        return [
            { title: $localize`:@@active:Active`, value: 'active', icon: 'notifications_active' },
            { title: $localize`Inactive (30d)`, value: 'inactive', icon: 'verified_user' },
            { title: $localize`Unactivated`, value: 'unactivated', icon: 'sync_disabled' },
            { title: $localize`Deleted`, value: 'deleted', icon: 'auto_delete' },
            { title: $localize`Expired`, value: 'expired', icon: 'event_busy' },
            { title: $localize`Imported`, value: 'imported', icon: 'label_important' },
        ];
    }

    managed_groups: number[];
    managed_divisions: number[];
    role: Observable<UserRole>;

    /**
     * Need extra validation when we will handle resource editing privilege.
     * And differentiate between each user role according
     * a. Resource author.
     * b. Resource customer. connected with a.
     * c. Resource public
     */
    public canEdit(resource: { customer_id: number; author_id?: number; disabled?: boolean }): boolean {
        return !resource.disabled && (this.isOwner(resource) || this.hasPrivilege(resource));
    }

    public canDownload(resource: { downloads: Downloads }): boolean {
        return !!resource.downloads;
    }
    // public canDownloadPDF(resource: Resource): boolean {
    //     // return (
    //     //     resource.occasionStatus === 'done' ||
    //     //     (resource.occasionStatus !== 'done' && this.role_value !== 'guest') ||
    //     //     (resource.occasionStatus !== 'done' && this.role_value !== 'user')
    //     // );
    //     // Only courses can be downloaded
    //     return (
    //         resource.downloads &&
    //         (ResourceTypes.parentType(resource.type_id) === ResourceTypes.COURSE ||
    //             resource.scope_id === AppScope.COURSE)
    //     );
    // }
    public canEditMultiple(users: { customer_id: number; author_id?: number }[]): boolean {
        return !users.find((user) => !this.canEdit(user));
    }

    public canEmail(resource: { customer_id: number; author_id?: number }): boolean {
        return this.canEdit(resource);
    }

    public hasViewPrivilege(resource: ResourceData): boolean {
        return (
            this.role_value === 'superadmin' ||
            this.isOwner(resource) ||
            resource.public ||
            resource.customer_id === this.customer_id
        );
    }

    public canPublish(resource: {
        customer_id: number;
        author_id?: number;
        ready_publish?: boolean;
        published: string;
        last_publish?: string;
    }): boolean {
        return !resource.last_publish && this.hasPublishPrivilege(resource);
    }

    // public canPublishResource(): boolean {
    //     return this.privileges?.publish === 'ALLOWED_PUBLISH';
    // }
    // public canRequestPublishResource(): boolean {
    //     return this.privileges?.publish === 'REQUEST_PUBLISH';
    // }

    // public canPublicResource(resourceData: { last_publish: boolean }): boolean {
    //     return resourceData.last_publish && this.privileges?.public === 'ALLOWED_PUBLISH';
    // }

    public canUnPublished(resource: {
        customer_id: number;
        author_id?: number;
        published: string;
        ready_publish?: boolean;
        last_publish?: string;
    }): boolean {
        return (resource.published || resource.last_publish) && this.hasPublishPrivilege(resource);
    }

    public hasPublishPrivilege(resource: {
        customer_id: number;
        ready_publish?: boolean;
        author_id?: number;
    }): boolean {
        return (
            (resource.ready_publish == null || resource.ready_publish) &&
            (this.hasPrivilege(resource) || (this.isOwner(resource) && !!this.permissions?.unpublished))
        );
    }

    public canAssign(resource: Resource): boolean {
        return (
            resource.isPublished() &&
            resource.isAvailable() &&
            (this.hasPrivilege(resource) || this.isOwner(resource) || resource.public)
        );
    }
    public canCollect(resource: Resource): boolean {
        return this.canAssign(resource);
    }
    public canMakeMandatory(resource: Resource): boolean {
        return this.canAssign(resource);
    }
    public canSetAccessControls(resource: Resource): boolean {
        return this.canAssign(resource);
    }
    public canRecommend(resource: Resource): boolean {
        return this.canAssign(resource);
    }
    public isMessageAuthor(message: Notification): boolean {
        return message.author?.id === this.id;
    }

    public canBrowse(resource: { customer_id: number; author_id?: number; published: string }): boolean {
        return !!resource.published;
    }

    public canPublicize(resource: {
        customer_id: number;
        author_id?: number;
        public: boolean;
        published: boolean;
    }): boolean {
        return (
            !resource.public &&
            !!resource.published &&
            (this.hasPrivilege(resource) || (this.isOwner(resource) && !!this.permissions?.publicize))
        );
    }

    public canUnPublicized(resource: { customer_id: number; author_id?: number; public: boolean }): boolean {
        return (
            resource.public &&
            (this.hasPrivilege(resource) || (this.isOwner(resource) && !!this.permissions?.unpublicized))
        );
    }

    /**
     * Check if user is the owner of resource if resoure has ownership author_id property
     */
    private isOwner(model: { author_id?: number; created_by?: number; user_id?: number }): boolean {
        return (
            this.role_value !== 'guest' &&
            ((model instanceof User && model.id === this.id) || // user can edit his profile.
                model.author_id === this.id ||
                model.created_by === this.id ||
                model.user_id === this.id)
        );
    }

    public canCheckOcc(occUser: User): boolean {
        return !!occUser && (this.id == occUser.id || this.hasPrivilege(occUser));
    }

    private hasPrivilege(resource: { customer_id: number }): boolean {
        return (
            this.role_value === 'superadmin' ||
            (resource.customer_id === this.customer_id &&
                (this.isAdmin() || (resource instanceof User && resource.role_value === 'user')))
        );
    }

    public isAdmin(): boolean {
        return this.role_value !== 'user' && this.role_value !== 'guest';
    }

    public isDivisionAdmin(): boolean {
        return this.role_value == 'divisionadmin';
    }
    public isGroupAdmin(): boolean {
        return this.role_value == 'groupadmin';
    }

    /**
     * Limited recording duration in ms when user is not admin
     * and his academy didn't pay for him (last condition not applied until EB clarify)
     */
    public canRecordUnlimited(): boolean {
        return this.role_value !== 'user' && this.role_value !== 'guest';
    }

    public isMasterAdmin(): boolean {
        return this.role_value === 'superadmin';
    }

    public preventChangeLibraryResStatus(isLibraryRes: boolean): boolean {
        return !this.isMasterAdmin() && isLibraryRes;
    }

    public canDelete(resource: { id: number; customer_id: number; author_id: number; default?: boolean }): boolean {
        return !resource.default && !this.isMe(resource) && this.canEdit(resource); // user can't delete his profile.
    }

    public canDeleteMultiple(users: any): boolean {
        return !users.find((user) => !this.canDelete(user));
    }

    private isMe(resource: { id: number }): boolean {
        return resource instanceof User && resource.id === this.id;
    }

    public canSendActivation(user: User): boolean {
        return this.canEdit(user) && !user.activated;
    }
    public sendActivationMail(user: User): { class: string; tooltip: string } {
        return !user.customer_published
            ? { class: 'greycolor', tooltip: $localize`Learner client is not activated yet` }
            : user.activated
            ? { class: 'normalColor', tooltip: $localize`Send an email` }
            : { class: 'redcolor', tooltip: $localize`Send activation mail` };
    }

    get roleValueSpecs(): { [key: string]: string } {
        const roles = {
            superadmin: { icon: 'admin_panel_settings', title: $localize`Master Admin`, color: '#ff9961' },
            customeradmin: { icon: 'groups', title: $localize`Main Admin`, color: '#3e5365' },
            divisionadmin: { icon: 'diversity_2', title: $localize`Division Admin`, color: '#3e5365' },
            groupadmin: { icon: 'group', title: $localize`Group Admin`, color: '#bfd8d0' },
            default: { icon: 'person', title: $localize`Learner`, color: '#dfdfdf' },
        };

        return roles[this.role_value] || roles.default;
    }

    get userPermissionLevels(): string[] {
        return ['MASTER', 'CLUSTER', 'PUBLIC', 'CUSTOMER', 'GROUP', 'USER'];
    }
    public getFilter(): Filter<string | number> {
        return {
            property: this.staffRoleProperty,
            items: [this.id],
            chips: [{ id: this.id, label: this.name, selected: true }],
        };
    }
}
export interface Privileges {
    public?: PrivilegeType;
    publish?: PrivilegeType;
}
